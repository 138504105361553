<template>
  <div class="boss-main">
    <div style="margin: 20px 0 0 20px"><MyBreadcrumb></MyBreadcrumb></div>
    <p class="tit">实战云数据大屏</p>
    <div class="cont">
      <div class="l">
        <div class="item">
          <div id="activation" class="line"></div>
        </div>
        <div class="item">
          <div id="brushedUser" class="line"></div>
        </div>
        <div class="item">
          <div id="brushed" class="line"></div>
        </div>
      </div>
      <div class="r">
        <div class="small">
          <div class="small-l">
            <div>
              <p class="sub-tit">本月题库刷题人数</p>
              <div style="height: calc(100% - 28px);overflow-y: auto;border:1px">
                <Table :columns="doQuestionUserStatColumns" :data="doQuestionUserStatData"></Table>
              </div>

            </div>
            <div>
              <p class="sub-tit">每月题库刷题</p>
              <div style="height: calc(100% - 28px);overflow-y: auto">
                <Table :columns="doQuestionCateStatPerMonthColumns" :data="doQuestionCateStatPerMonthData"></Table>
              </div>

            </div>
          </div>
          <div class="small-b">
            <div style="height: 100%;overflow-y: auto">
              <Table :columns="newQuestionPerWeekColumns" :data="newQuestionPerWeekData"></Table>
            </div>
            <div style="height: 100%;overflow-y: auto">
              <Table :columns="newFeedbackPerWeekColumns" :data="newFeedbackPerWeekData"></Table>
            </div>
            <div style="height: 100%;overflow-y: auto">
              <Table :columns="newUserPerWeekColumns" :data="newUserPerWeekData"></Table>
            </div>
          </div>
        </div>
        <div class="big">
          <p class="sub-tit">题库累计刷题数</p>
          <div style="height: calc(100% - 28px);overflow-y: auto">
            <Table :columns="doQuestionCateStatColumns" :data="doQuestionCateStatData"></Table>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'boss',
  data(){
    return {
      doQuestionCateStatColumns:[],
      doQuestionCateStatData:[],
      doQuestionCateStatPerMonthColumns:[],
      doQuestionCateStatPerMonthData:[],
      doQuestionUserStatColumns:[],
      doQuestionUserStatData:[],
      newQuestionPerWeekColumns:[],
      newQuestionPerWeekData:[],
      newFeedbackPerWeekColumns:[],
      newFeedbackPerWeekData:[],
      newUserPerWeekColumns:[],
      newUserPerWeekData:[],
      usedCodePerMonth:{},  //每月激活码激活数
      doQuestionUserCountPerMonth:{},  //每月刷题用户数
      doQuestionCountPerMonth:{},  //每月刷题数
      doQuestionUserStat:{},  //本月题库刷题人数
      doQuestionCateStatPerMonth:{},  //每月题库刷题
      doQuestionCateStat:{},  //题库累计刷题数
      newQuestionPerWeek:{},  //新增试题数
      newFeedbackPerWeek:{},  //新增反馈数
      newUserPerWeek:{},  //新增用户数
    }
  },
  mounted(){
    this.getData();
  },
  methods:{
    getData(){  //获取数据
      this.api.dataset.bossIndex().then((res)=>{
        this.usedCodePerMonth = res.used_code_per_month;
        this.doQuestionUserCountPerMonth = res.do_question_user_count_per_month;
        this.doQuestionCountPerMonth = res.do_question_count_per_month;
        this.doQuestionUserStat = res.do_question_user_stat;
        this.doQuestionCateStatPerMonth = res.do_question_cate_stat_per_month;
        this.doQuestionCateStat = res.do_question_cate_stat;
        this.newQuestionPerWeek = res.new_question_per_week;
        this.newFeedbackPerWeek = res.new_feedback_per_week;
        this.newUserPerWeek = res.new_user_per_week;

        this.init();

        this.doQuestionUserStatColumns = this.formateColumes(this.doQuestionUserStat.columns);
        this.doQuestionUserStatData = this.doQuestionUserStat.rows;

        this.doQuestionCateStatPerMonthColumns = this.formateColumes(this.doQuestionCateStatPerMonth.columns);
        this.doQuestionCateStatPerMonthData = this.doQuestionCateStatPerMonth.rows;

        this.doQuestionCateStatColumns = this.formateColumes(this.doQuestionCateStat.columns);
        this.doQuestionCateStatData = this.doQuestionCateStat.rows;

        this.newQuestionPerWeekColumns = this.formateColumesAndSort(this.newQuestionPerWeek.columns);
        this.newQuestionPerWeekData = this.newQuestionPerWeek.rows;

        this.newFeedbackPerWeekColumns = this.formateColumesAndSort(this.newFeedbackPerWeek.columns);
        this.newFeedbackPerWeekData = this.newFeedbackPerWeek.rows;

        this.newUserPerWeekColumns = this.formateColumesAndSort(this.newUserPerWeek.columns);
        this.newUserPerWeekData = this.newUserPerWeek.rows;

      });
    },
    init(){
      this.setActivation();
      this.setBrushedUser();
      this.setBrushed();
    },
    setActivation(){
      //每月激活码激活数
      var chartDom = document.getElementById('activation');
      var myChart = this.$echarts.init(chartDom);


      var option;

      option = {
        title:{
          //text:"每月激活码激活数",
          subtext: '每月激活码激活数',
          left: 'center',
        },
        xAxis: {
          type: 'category',
          data: this.getValues(this.usedCodePerMonth.categories),
          axisLabel:{
            rotate : 60
          },
        },
        yAxis: {
          type: 'value'
        },
        grid:[
          {
            left: 50,
          }
        ],
        series: [
          {
            data: this.getValues(this.usedCodePerMonth.series && this.usedCodePerMonth.series[0].data),
            type: 'line',
            smooth: true,
            itemStyle:{
              normal:{
                label : {
                  show: true // 在折线拐点上显示数据
                },
              }
            }
          }
        ]
      };

      option && myChart.setOption(option);
    },
    setBrushedUser(){
      var chartDom = document.getElementById('brushedUser');
      var myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        title:{
          //text:"每月刷题用户数",
          subtext: '每月刷题用户数',
          left: 'center',
        },
        xAxis: {
          type: 'category',
          data: this.getValues(this.doQuestionUserCountPerMonth.categories),
          axisLabel:{
            rotate : 60
          },
        },
        yAxis: {
          type: 'value'
        },
        grid:[
          {
            left: 50,
          }
        ],
        series: [
          {
            data: this.getValues(this.doQuestionUserCountPerMonth.series && this.doQuestionUserCountPerMonth.series[0].data),
            type: 'line',
            smooth: true,
            itemStyle:{
              normal:{
                label : {
                  show: true // 在折线拐点上显示数据
                },
              }
            }
          }
        ]
      };

      option && myChart.setOption(option);
    },
    setBrushed(){
      var chartDom = document.getElementById('brushed');
      var myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        title:{
          //text:"每月刷题数",
          subtext: '每月刷题数',
          left: 'center',
        },
        xAxis: {
          type: 'category',
          data: this.getValues(this.doQuestionCountPerMonth.categories),
          axisLabel:{
            rotate : 60
          },
        },
        yAxis: {
          type: 'value'
        },
        grid:[
          {
            left: 70,
          }
        ],
        series: [
          {
            data: this.getValues(this.doQuestionCountPerMonth.series && this.doQuestionCountPerMonth.series[0].data),
            type: 'line',
            smooth: true,
            itemStyle:{
              normal:{
                label : {
                  show: true // 在折线拐点上显示数据
                },
              }
            }
          }
        ]
      };

      option && myChart.setOption(option);
    },
    getValues(data){
      let arr = [];

      for(let name in data){
        arr.push(data[name]);
      }

      return arr;
    },
    formateColumes(data){
      let obj = data.map((item)=>{
        return {
          title:item.name,
          key:item.id
        }
      });
      console.log(obj,'dddd')
      return obj;
    },
    formateColumesAndSort(data){
      let obj = data.map((item)=>{
        return {
          title:item.name,
          key:item.id,
          // sortable: true,
          // sortMethod:()=>{
          //
          // }
        }
      });
      console.log(obj,'dddd')
      return obj;
    }
  }
}
</script>
<style scoped lang="scss">
.boss-main{
  width: calc(100vw - 200px);//
  height: calc(100vh - 48px);
  overflow: hidden;
  .tit{
    margin: 10px 0;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .cont{
    margin-left: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    width: calc(100% - 38px);
    height: calc(100% - 38px);

    .l{
      width: 30%;
      //padding:1px;
      height: 100%;
      background: #FFFFFF;
      //box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
      border-radius: 1px;
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;

      .item{
        height: 33%;
        .sub-tit{
          text-align: center;
        }
        .line{
          //flex: 1;
          width: 100%;
          height: 100%;
        }
      }
    }
    .r{
      overflow: hidden;
      margin-left: 0px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      padding-top:10px;
      background: #FFFFFF;
      //box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
      border-radius: 4px;
      .small{
        flex: 2;

        display: flex;
        flex-wrap: wrap;

        >div{
          height: 50%;
          display: flex;
          justify-content: flex-start;
          >div{
            flex: 1;
            margin-right: 0;
          }
          >div:last-child{
            margin-right: 0;
          }
        }
      }
      .small-l{
        width: 100%;
      }
      .small-b{
        width: 100%;
        margin-top: 20px;
        height: calc(50% - 20px) !important;
      }
      .sub-tit{
        margin-bottom: 10px;
        text-align: center;
      }
      .page{
        margin-top: 10px;
        margin-bottom: 14px;
        text-align: right;
      }
      .big{
        flex: 1;
        margin-left: 20px;
      }
    }
  }
}
</style>
